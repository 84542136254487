import React from "react";
import { ChevronIcon } from "components/Icons/ChevronIcon";
import { PrismicLink } from "components/PrismicLink/PrismicLink";
import { AnyLink } from "interfaces/interfaces";
import styles from "./blockHeading.module.scss";

interface IBlockHeadingProps {
  link?: AnyLink;
  heading: string;
}

export const BlockHeading = (props: IBlockHeadingProps) => {
  return (
    <div className={styles.header}>
      {props.link ? (
        <PrismicLink link={props.link} className={styles.link}>
          <h2 className={styles.heading}>{props.heading}</h2>
          <ChevronIcon className={styles.arrow} size={30} />
        </PrismicLink>
      ) : (
        <h2 className={styles.heading}>{props.heading}</h2>
      )}
    </div>
  );
};
