import { sort } from "fast-sort";
import React from "react";
import { BlockHeading } from "components/Blocks/BlockHeading/BlockHeading";
import { Block } from "components/Blocks/Blocks";
import { PodcastList } from "components/PodcastList/PodcastList";
import { usePodcastToplist } from "hooks/usePodcastToplist";
import { Language } from "interfaces/interfaces";

export interface IProps {
  analyticsListName: string | null;
  analyticsListId: string;
  language: Language;
  category?: {
    id: number;
    name: string;
  };
  heading?: string;
  limit: number;
  original: boolean;
}

export const PodcastListBlock = ({
  heading,
  language,
  category,
  analyticsListName,
  analyticsListId,
  original,
  limit,
}: IProps) => {
  const { data: podcasts } = usePodcastToplist({
    language: language,
    categoryId: category?.id,
    original,
    limit,
    sortFunction: (podcasts) => sort(podcasts).asc((podcast) => podcast.title),
  });

  return (
    <Block data-testid="podcast-toplist">
      {heading && <BlockHeading heading={heading} />}
      <PodcastList
        analyticsListId={analyticsListId}
        analyticsListName={analyticsListName}
        podcasts={podcasts}
      />
    </Block>
  );
};
