import React, { ReactElement } from "react";
import { BlockHeading } from "components/Blocks/BlockHeading/BlockHeading";
import { Block } from "components/Blocks/Blocks";
import { usePodcastListViewed } from "hooks/usePodcastListViewed";
import { PodcastToplistItem } from "./PodcastToplistItem";
import styles from "./podcastToplist.module.scss";

interface IProps {
  analyticsListName: string | null;
  analyticsListId: string;
  podcasts?: {
    id: number;
    title: string;
    author: string;
    original: boolean;
    popularity: number;
    image: string;
    language_iso: string;
    description: string;
  }[];
  heading?: string;
}

export const PodcastToplist = ({
  podcasts,
  heading,
  analyticsListName,
  analyticsListId,
}: IProps): ReactElement => {
  const setRef = usePodcastListViewed(
    podcasts,
    analyticsListId,
    analyticsListName
  );

  return (
    <Block data-testid="podcast-toplist">
      {heading && <BlockHeading heading={heading} />}
      {!!podcasts?.length && (
        <ol
          className={styles.list}
          data-testid="toplist-page-list"
          ref={setRef}
        >
          {podcasts.map((podcast, index) => (
            <PodcastToplistItem
              key={podcast.id}
              podcast={podcast}
              listNumber={index + 1}
              index={index}
              analyticsListId={analyticsListId}
              analyticsListName={analyticsListName}
            />
          ))}
        </ol>
      )}
    </Block>
  );
};
