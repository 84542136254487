import React from "react";
import { BlockHeading } from "components/Blocks/BlockHeading/BlockHeading";
import { Block } from "components/Blocks/Blocks";
import { CollectionLinks } from "components/CollectionLinks/CollectionLinks";
import { AnyLink, ICollectionLink } from "interfaces/interfaces";

interface IProps {
  collections: ICollectionLink[] | null;
  heading?: string | null;
  link: AnyLink;
}

export const CollectionCloud = (props: IProps) => {
  if (!props.collections) {
    return null;
  }
  return (
    <Block data-testid="categories">
      {props.heading && (
        <BlockHeading heading={props.heading} link={props.link} />
      )}
      <CollectionLinks collectionsLinks={props.collections} />
    </Block>
  );
};
