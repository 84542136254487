import React, { HTMLProps, ReactElement, useMemo } from "react";
import { CollectionCloud } from "components/CollectionCloud/CollectionCloud";
import { PodcastCarousel } from "components/PodcastCarousel/PodcastCarousel";
import { PodcastListBlock as PodcastList } from "components/PodcastListBlock/PodcastListBlock";
import { PodcastToplist } from "components/PodcastToplist/PodcastToplist";
import { parseLocale } from "helpers/parseLocale";
import { isTruthy } from "helpers/typeGuards";
import { useTypedRouter } from "hooks/useTypedRouter";
import {
  AnyBlock,
  BlockType,
  ICollectionCloud,
  ICollectionPage,
  IPodcastCollection,
  IPodcastList,
  IPodcastToplist,
  LinkType,
  PageType,
} from "interfaces/interfaces";
import styles from "./blocks.module.scss";

export const isBlock = <T extends AnyBlock>(
  b: AnyBlock,
  type: BlockType | BlockType[]
): b is T => type.includes(b._meta.type);

export const Blocks = (props) => {
  return <div className={styles.blocks}>{props.children}</div>;
};

export const Block = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div className={styles.block} {...props}>
      {props.children}
    </div>
  );
};

export const CollectionBlockPodcastCarousel = ({
  block,
}: {
  block: IPodcastCollection;
}) => {
  // NOTICE: We need to create a memoized version of podcasts here to avoid
  // re trigger side effects further down.
  const podcasts = useMemo(
    () => block.podcasts.map((podcast) => podcast.podcast).filter(isTruthy),
    [block]
  );
  return (
    <PodcastCarousel
      dataTestId={`collection-carousel`}
      analyticsListName={block.document_name}
      analyticsListId={block._meta.id}
      podcasts={podcasts}
      heading={block?.heading}
    />
  );
};

export const CollectionPagePodcastCarousel = ({
  block,
}: {
  block: ICollectionPage;
}): ReactElement => {
  const { locale } = useTypedRouter();

  // NOTICE: We need to create a memoized version of podcasts here to avoid
  // re trigger side effects further down.
  const podcastsBlock = useMemo(() => {
    // Find first block that contains podcasts.
    return block.blocks.find((b) => {
      return (
        isBlock<IPodcastToplist>(b, [BlockType.PODCAST_TOPLIST_BLOCK]) ||
        isBlock<IPodcastCollection>(b, [BlockType.PODCAST_COLLECTION_BLOCK])
      );
    });
  }, [block]);

  const podcasts = useMemo(() => {
    if (!podcastsBlock) {
      return [];
    }

    // Extract podcasts from the first podcast block.
    if (
      isBlock<IPodcastToplist>(podcastsBlock, BlockType.PODCAST_TOPLIST_BLOCK)
    ) {
      return podcastsBlock.podcasts.filter(isTruthy) || [];
    }
    if (
      isBlock<IPodcastCollection>(
        podcastsBlock,
        BlockType.PODCAST_COLLECTION_BLOCK
      )
    ) {
      return (
        podcastsBlock.podcasts.map(({ podcast }) => podcast).filter(isTruthy) ||
        []
      );
    }

    return [];
  }, [podcastsBlock]);

  const analyticsListName = podcastsBlock ? podcastsBlock.document_name : null;

  return (
    <PodcastCarousel
      dataTestId={`collectionPage-carousel`}
      analyticsListName={analyticsListName}
      analyticsListId={podcastsBlock ? podcastsBlock._meta.id : block._meta.id}
      podcasts={podcasts}
      link={{
        _linkType: LinkType.Document,
        _meta: {
          uid: block._meta.uid,
          lang: locale,
          type: PageType.COLLECTION_PAGE,
        },
      }}
      heading={block?.heading}
    />
  );
};

export const PodcastToplistBlock = ({
  block,
}: {
  block: IPodcastToplist;
}): ReactElement => {
  const podcasts = useMemo(() => block.podcasts.filter(isTruthy), [block]);

  return (
    <PodcastToplist
      analyticsListName={block.document_name}
      analyticsListId={block._meta.id}
      heading={block.heading}
      podcasts={podcasts}
    />
  );
};

export const PodcastListBlock = ({
  block,
}: {
  block: IPodcastList;
}): ReactElement => {
  // const podcasts = useMemo(() => block.podcasts.filter(isTruthy), [block]);

  const { language } = parseLocale(block._meta.lang);

  return (
    <PodcastList
      original={block.original}
      limit={block.limit}
      language={block.language || language}
      category={block.category}
      analyticsListName={block.document_name}
      analyticsListId={block._meta.id}
      heading={block.heading}
    />
  );
};

export const CollectionCloudBlock = ({
  block,
}: {
  block: ICollectionCloud;
}): ReactElement => {
  const collections =
    useMemo(() => block?.collections?.filter(isTruthy), [block]) || null;
  return (
    <CollectionCloud
      link={block.link}
      heading={block.heading}
      collections={collections}
    />
  );
};
