import { stringify } from "query-string";
import useSWR, { SWRConfiguration } from "swr";
import { podplayFetch } from "helpers/podplayFetch";
import { removeNullishProperties } from "helpers/removeNullishProperties";
import { IPodcast, Language } from "interfaces/interfaces";

interface IResponse {
  results: IPodcast[];
}

interface IProps {
  language: Language;
  categoryId?: number;
  original?: boolean;
  limit?: number;
  sortFunction?: (podcasts: IPodcast[]) => IPodcast[];
  config?: SWRConfiguration;
}

const podcastToplistFetcher = async (url: string, sortFunction) => {
  const response = await podplayFetch<IResponse>(url);
  if (response.ok) {
    const results = response.body.results;
    return sortFunction
      ? Promise.resolve(sortFunction(results))
      : Promise.resolve(results);
  }
  return Promise.reject(response);
};

export const usePodcastToplist = (props: IProps) => {
  // The Podplay api can't handle query parameters with nullish values.
  const options = removeNullishProperties({
    original: props.original,
    category_id: props.categoryId,
    limit: props.limit,
  });

  // Build url search suffix.
  const encodedSearchParams = options && stringify(options);
  const search = encodedSearchParams ? `?${encodedSearchParams}` : "";
  const url = `/${props.language}/toplist${search}`;

  return useSWR(
    url,
    (url) => podcastToplistFetcher(url, props.sortFunction),
    props.config
  );
};
