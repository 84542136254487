import React, { useCallback } from "react";
import { BlockHeading } from "components/Blocks/BlockHeading/BlockHeading";
import { Block } from "components/Blocks/Blocks";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import { PodcastLink } from "components/PodcastLink/PodcastLink";
import { podcastInPodcastListClicked } from "helpers/ecommerceAnalytics";
import { usePodcastListViewed } from "hooks/usePodcastListViewed";
import { AnyLink } from "interfaces/interfaces";
import styles from "./podcastCarousel.module.scss";

interface IPodcast {
  id: number;
  title: string;
  author: string;
  image: string;
  popularity: number;
  original: boolean;
}

interface IProps {
  dataTestId: string;
  analyticsListName: string | null;
  analyticsListId: string;
  podcasts?: IPodcast[];
  heading?: string | null;
  link?: AnyLink;
}

export const PodcastCarousel = (props: IProps) => {
  const onClick = useCallback(
    (podcast: IPodcast, index: number) => {
      if (!props.analyticsListName) {
        return;
      }
      podcastInPodcastListClicked(
        {
          id: props.analyticsListId,
          name: props.analyticsListName,
        },
        podcast,
        index
      );
    },
    [props.analyticsListId, props.analyticsListName]
  );

  const setRef = usePodcastListViewed(
    props.podcasts,
    props.analyticsListId,
    props.analyticsListName
  );

  if (!props.podcasts) {
    return null;
  }

  return (
    <Block data-testid={props.dataTestId}>
      {props.heading && (
        <BlockHeading link={props.link} heading={props.heading} />
      )}
      <div className={styles.listContainer}>
        <ul className={styles.list} ref={setRef}>
          {props.podcasts.map((podcast, index) => (
            <li className={styles.listItem} key={podcast.title}>
              <PodcastLink
                podcast={podcast}
                onClick={() => onClick(podcast, index)}
              >
                <PodcastImage
                  className={styles.image}
                  src={podcast.image}
                  alt={podcast.title}
                  title={podcast.title}
                  fallbackTitle={podcast.title}
                  width={158}
                  height={158}
                />
              </PodcastLink>
            </li>
          ))}
        </ul>
      </div>
    </Block>
  );
};
