import React, { ReactElement, useCallback } from "react";
import { PodcastImage } from "components/PodcastImage/PodcastImage";
import { PodcastLink } from "components/PodcastLink/PodcastLink";
import styles from "components/PodcastToplist/podcastToplistItem.module.scss";
import { podcastInPodcastListClicked } from "helpers/ecommerceAnalytics";

interface IProps {
  podcast: {
    id: number;
    title: string;
    author: string;
    original: boolean;
    popularity: number;
    image: string;
    description: string;
    language_iso: string;
  };
  listNumber: number;
  analyticsListName: string | null;
  analyticsListId: string;
  index: number;
}

export const PodcastToplistItem = ({
  listNumber,
  podcast,
  analyticsListName,
  analyticsListId,
  index,
}: IProps): ReactElement => {
  const onClick = useCallback(() => {
    if (!analyticsListName) {
      return;
    }
    podcastInPodcastListClicked(
      {
        id: analyticsListId,
        name: analyticsListName,
      },
      podcast,
      index
    );
  }, [analyticsListId, analyticsListName, podcast, index]);

  return (
    <li className={styles.container}>
      <PodcastLink podcast={podcast} className={styles.link} onClick={onClick}>
        <div>
          <PodcastImage
            className={styles.image}
            src={podcast.image}
            alt={podcast.title}
            width={110}
            height={110}
          />
          <div className={styles.textContent}>
            <h2>
              <span className={styles.number} data-ignore-a11y="color-contrast">
                {listNumber}.
              </span>
              {podcast.title}
            </h2>
            <p className={styles.description} lang={podcast.language_iso}>
              {podcast.description}
            </p>
          </div>
        </div>
      </PodcastLink>
    </li>
  );
};
