import { ImageLoader } from "next/dist/client/image";
import NextImage, { ImageProps } from "next/image";
import { addQueryParameters } from "helpers/addQueryParameters";

const loader: ImageLoader = (props) => {
  const params: Record<string, string> = {};
  if (props.width) {
    params.width = props.width.toString(10);
  }
  if (props.quality) {
    params.quality = props.quality.toString(10);
  }
  return addQueryParameters(props.src, params);
};

export function PrismicImage(props: Omit<ImageProps, "loader">) {
  return <NextImage loader={loader} {...props} />;
}
