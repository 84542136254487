import { asText } from "@prismicio/helpers";
import { PrismicRichText, PrismicLink, LinkProps } from "@prismicio/react";
import NextLink from "next/link";
import Script from "next/script";
import { ReactElement } from "react";
import { IFaq } from "interfaces/interfaces";
import { linkResolver } from "pages/api/preview";
import styles from "./faq.module.scss";

interface IFaqProps {
  block: IFaq;
}

export const Faq = (props: IFaqProps): ReactElement | null => {
  // Remove questions without question or answer.
  const questions = props.block.questions.filter((faq) => {
    return faq.question && faq.answer;
  });

  const heading = props.block.heading;

  // If there are no questions, return null.
  if (!questions.length) {
    return null;
  }

  // Build structured data for LD+JSON.
  // https://developers.google.com/search/docs/advanced/structured-data/faqpage
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: props.block.questions.map((faq) => {
      return {
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: asText(faq.answer),
        },
      };
    }),
  };

  return (
    <>
      <Script
        id="faq"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      <div className={styles.faq}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        <dl className={styles.questions}>
          {questions.map((faq) => (
            <div key={faq.question} className={styles.row}>
              <dt className={styles.question}>{faq.question}</dt>
              <dd className={styles.answer}>
                <PrismicRichText
                  field={faq.answer}
                  components={{
                    paragraph: ({ children }) => <p>{children}</p>,
                    hyperlink: ({ node, children, key }) => (
                      <PrismicLink
                        key={key}
                        field={node.data}
                        linkResolver={linkResolver}
                        internalComponent={(props: LinkProps) => {
                          return <NextLink {...props} />;
                        }}
                        externalComponent={(props: LinkProps) => (
                          <a {...props} target={"_blank"} />
                        )}
                      >
                        <>{children}</>
                      </PrismicLink>
                    ),
                  }}
                />
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
};
