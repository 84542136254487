import Link from "next/link";
import { PrismicImage } from "components/PrismicImage/PrismicImage";
import { buildCollectionUrl } from "helpers/url";
import { ICollectionLink } from "interfaces/interfaces";
import styles from "./collectionLink.module.scss";

interface IProps {
  collection: ICollectionLink | null;
  imageLoading?: "lazy" | "eager" | undefined;
}

export const CollectionLink = ({ collection, imageLoading }: IProps) => {
  if (collection === null) {
    return null;
  }

  return (
    <Link legacyBehavior href={buildCollectionUrl(collection.slug)}>
      <a className={styles.link}>
        {collection.imageUrl && (
          <PrismicImage
            src={collection.imageUrl}
            alt=""
            sizes="278px"
            className={styles.image}
            loading={imageLoading}
            fill
          />
        )}
        <div className={styles.overlay} />
        <span className={styles.heading}>{collection.title}</span>
      </a>
    </Link>
  );
};
