import { IPreviewData, PrismicClient } from "api/prismic/prismic";
import {
  buildContentUrl,
  buildCollectionUrl,
  buildEpisodeUrl,
  buildHomepageUrl,
  buildPodcastUrl,
  buildStartpageUrl,
  buildUserUrl,
} from "helpers/url";
import { IEpisode, IPodcast, Locale, PageType } from "interfaces/interfaces";

// The Prismic Episode Page applies to all Episode Pages.
// So we use a dummy episode page for preview.
const PREVIEW_EPISODE_PODCAST = { id: 811561 } as IPodcast;
const PREVIEW_EPISODE_EPISODE = { id: 71734132 } as IEpisode;

export function linkResolver(doc): string {
  switch (doc.type) {
    case PageType.START_PAGE:
      // We need to preview the English locale on podplay.com/en
      // instead of podplay.com to circumvent the localeDetection redirects.
      // TODO: Run preview on a specialized instance (preview.podplay.com) without localeDetection and cache.
      return doc.lang === Locale.en
        ? "/en" + buildStartpageUrl(doc.lang)
        : buildStartpageUrl(doc.lang);
    case PageType.COLLECTION_PAGE:
      return buildCollectionUrl(doc.uid, doc.lang);
    case PageType.HOME_PAGE:
      return buildHomepageUrl(doc.lang);
    case PageType.PODCAST_PAGE:
      return buildPodcastUrl(doc.uid, undefined, doc.lang);
    case PageType.EPISODE_PAGE:
      return buildEpisodeUrl(
        PREVIEW_EPISODE_PODCAST,
        PREVIEW_EPISODE_EPISODE,
        doc.lang
      );
    case PageType.USER_PAGE:
      return buildUserUrl(doc.lang);
    case PageType.CONTENT_PAGE:
      return buildContentUrl(doc.slug, doc.lang);
    default:
      return "/";
  }
}

export default async function preview(req, res) {
  const { token: ref, documentId } = req.query;

  // Check the token parameter against the Prismic SDK
  const url = await PrismicClient.getPreviewResolver(ref, documentId).resolve(
    linkResolver,
    "/"
  );

  if (!url) {
    return res.status(401).json({ message: "Invalid token" });
  }

  // Enable Preview Mode by setting the cookies
  res.setPreviewData({
    ref, // pass the ref to pages so that they can fetch the draft ref
  } as IPreviewData);

  // Redirect the user to the share endpoint from same origin. This is
  // necessary due to a Chrome bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=696204
  res.write(
    `<!DOCTYPE html><html><head><meta http-equiv="Refresh" content="0; url=${url}" />
    <script>window.location.href = '${url}'</script>
    </head>`
  );

  res.end();
}
