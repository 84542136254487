import { CollectionLink } from "components/CollectionLink/CollectionLink";
import styles from "components/CollectionLinks/collectionLinks.module.scss";
import { ICollectionLink } from "interfaces/interfaces";

export interface IProps {
  collectionsLinks: Array<ICollectionLink | null>;
}

export const CollectionLinks = ({ collectionsLinks }: IProps) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {collectionsLinks.map(
          (collection) =>
            collection && (
              <li key={collection.slug}>
                <CollectionLink collection={collection} />
              </li>
            )
        )}
      </ul>
    </div>
  );
};
